.pagination {
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0;

  //  All lis
  li {
    display: block;
    margin: 0 0.5rem;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.5rem;
      width: 1.5rem;
      font-weight: 500;
      border-radius: 100%;
      font-size: 12px;
      color: $light;
      transition: all 0.4s ease-in-out;

      &:hover {
        background-color: $gray200;
      }
    }

    &.active {
      a {
        background-color: $brand700;
        color: $white;

        &:hover {
          background-color: $brand700;
        }
      }
    }
  }

  //   Previous Button
  li.previous {
    margin: 0 1rem 0 0;
  }

  li.next {
    margin: 0 0 0 1rem;
  }

  // Prev and next button
  li.previous,
  li.next {
    a {
      height: 2rem;
      width: 2rem;
      border-radius: 8px;
      border: 1px solid $o-light;
      box-shadow: 0 2px 8px 0 rgba($black800, 0.08);

      svg path {
        fill: $brand600;
      }

      &:hover {
        background-color: $white;
      }
    }

    // Disabled Button
    &.disabled a {
      height: 2rem;
      width: 2rem;
      border-radius: 8px;
      background-color: $gray300;
      border: 1px solid $gray300;
      box-shadow: none;
      cursor: not-allowed;

      svg path {
        fill: $disabled;
      }

      &:hover {
        background-color: $gray300;
      }
    }
  }
}
