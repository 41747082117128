// ####################################### //
// ## RESET.SCSS ## //
// ####################################### //

// Light
@font-face {
  font-family: "matter";
  src: url("../fonts/matter-light.otf");
  font-weight: 300;
  font-style: normal;
}

// Light Italic
@font-face {
  font-family: "matter";
  src: url("../fonts/matter-lightitalic.otf");
  font-weight: 300;
  font-style: italic;
}

// Regular
@font-face {
  font-family: "matter";
  src: url("../fonts/matter-regular.otf");
  font-weight: 400;
  font-style: normal;
}

// Regular Italic
@font-face {
  font-family: "matter";
  src: url("../fonts/matter-regularitalic.otf");
  font-weight: 400;
  font-style: italic;
}

// Medium
@font-face {
  font-family: "matter";
  src: url("../fonts/matter-medium.otf");
  font-weight: 500;
  font-style: normal;
}

// Medium Italic
@font-face {
  font-family: "matter";
  src: url("../fonts/matter-mediumitalic.otf");
  font-weight: 500;
  font-style: italic;
}

// Semibold
@font-face {
  font-family: "matter";
  src: url("../fonts/matter-semibold.otf");
  font-weight: 600;
  font-style: normal;
}

// Semibold Italic
@font-face {
  font-family: "matter";
  src: url("../fonts/matter-semibolditalic.otf");
  font-weight: 600;
  font-style: italic;
}

// Bold
@font-face {
  font-family: "matter";
  src: url("../fonts/matter-bold.otf");
  font-weight: 700;
  font-style: normal;
}

// Bold Italic
@font-face {
  font-family: "matter";
  src: url("../fonts/matter-bolditalic.otf");
  font-weight: 700;
  font-style: italic;
}

// heavy
@font-face {
  font-family: "matter";
  src: url("../fonts/matter-heavy.otf");
  font-weight: 800;
  font-style: normal;
}

// heavy Italic
@font-face {
  font-family: "matter";
  src: url("../fonts/matter-heavyitalic.otf");
  font-weight: 800;
  font-style: italic;
}
