// ######################################## //
// ## UTILITY-CLASSES.SCSS ## //
// ######################################## //

// Truncate text
.truncate-text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Custom dashed stroked
.custom-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23c9ced6ff' stroke-width='2' stroke-dasharray='1%2c5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

// Hide element
.hide {
  pointer-events: none;
  opacity: 0;
}
