.thumb-swiper {
  position: relative;
  overflow: hidden;

  .swiper-slide {
    opacity: 0.4;

    &.currently-active {
      opacity: 1;
    }

    // &.swiper-slide-active {
    //   opacity: 1;
    // }
  }

  &.not-first {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0)
      );
      width: 2.5rem;
      z-index: 2;
      pointer-events: none;
    }
  }

  &.not-last {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1)
      );
      width: 2.5rem;
      z-index: 1;
      pointer-events: none;
    }
  }
}
