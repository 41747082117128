// ###################### //
// ######## SLIDER.SCSS ######### //
// ###################### //

// Double Slider
.double-slider {
  width: 100%;
  padding: 12px 0;

  // Tracking balls
  .track-ball {
    cursor: pointer;
    height: 24px;
    width: 24px;
    background-color: $white;
    border: 1px solid $o-dark;
    border-radius: 100%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      border-color: $brand400;
    }
  }

  // The paths of values
  .track-path {
    top: 11px;
    height: 2px;
    background-color: $o-base;

    &.track-path-1 {
      background-color: $brand700;
    }
  }
}

// Double Slider Secondary
.double-slider-secondary {
  width: 100%;
  padding: 12px 0;

  // Tracking balls
  .track-ball {
    height: 12px;
    width: 12px;
    background-color: $white;
    border: 1px solid $o-base;
    border-radius: 100%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 6px rgba(10, 31, 68, 0.08);

    &:focus {
      border-color: $brand400;
      box-shadow: 0px 2px 6px rgba(10, 31, 68, 0.28);
    }
  }

  // The paths of values
  .track-path {
    top: 11px;
    height: 2px;
    background-color: $o-base;

    &.track-path-1 {
      background-color: $brand700;
    }
  }
}
