// ####################################### //
// ## COLORS.SCSS ## //
// ####################################### //

$black: #000000;
$white: #fff;
$transparent: rgba(0, 0, 0, 0);

$brand900: #00288d;
$brand800: #0037c4;
$brand700: #0948ea;
$brand600: #2260ff;
$brand500: #6197ff;
$brand400: #9fbfff;
$brand300: #dceeff;
$brand200: #eef7ff;
$brand100: #f4f8ff;

$gray100: #fafbfb;
$gray200: #f7f8f9;
$gray300: #f1f2f4;
$gray400: #e7eaed;
$gray500: #c9ced6;
$gray600: #b0b7c3;
$gray700: #a7aebb;
$gray800: #98a1b1;
$gray900: #8a94a6;

$black100: #717d92;
$black200: #627088;
$black300: #596780;
$black400: #4e5d78;
$black500: #455571;
$black600: #364766;
$black700: #283a5b;
$black800: #14284b;
$black900: #0a1f44;

$o-light: #f1f2f4;
$o-medium: #e7eaed;
$o-base: #c9ced6;
$o-dark: #b0b7c3;

$disabled: #b0b7c3;
$light: #8a94a6;
$medium: #4e5d78;
$dark: #0a1f44;

$danger100: #fff8f8;
$danger200: #ffeaea;
$danger300: #ffc2c2;
$danger400: #ff8d8d;
$danger500: #ff5d5d;
$danger600: #f03d3d;
$danger700: #dd2727;
$danger800: #bd0303;
$danger900: #a50000;

$info100: #f8fbff;
$info200: #eef7ff;
$info300: #dceeff;
$info400: #b3daff;
$info500: #83c3fe;
$info600: #4ba7fe;
$info700: #0284fe;
$info800: #026dd6;
$info900: #01408f;

$warning100: #fffcf5;
$warning200: #fff6e4;
$warning300: #ffecc7;
$warning400: #ffdc99;
$warning500: #ffca65;
$warning600: #ffbb38;
$warning700: #ffad0d;
$warning800: #ff8f00;
$warning900: #f07300;

$success100: #f2fefb;
$success200: #d9f4ec;
$success300: #b2f1dd;
$success400: #85e4c6;
$success500: #3ed3a3;
$success600: #22c993;
$success700: #0bb07b;
$success800: #00865a;
$success900: #006242;
