button.disabled-color:disabled {
  background-color: #0037c4;
}

button:disabled svg {
  opacity: 0.2;
}

.underline-focus {
  &:focus {
    box-shadow: none;
    // text-decoration: underline;
  }
}

.no-focus {
  &:focus {
    box-shadow: none;
  }
}
