.simple-dropdown {
  &:focus {
    box-shadow: none;
    text-decoration: underline;
  }
}

.make-dropdown,
.select-modal-dropdown {
  &:focus {
    box-shadow: 0 0 0 2px rgba($brand400, $alpha: 0.4);
  }
}
.dropdown--menu {
  overscroll-behavior: contain !important;
}

.edit-searchable-dropown > div {
  height: 100%;
}

input.make-dropdown::placeholder {
  font-weight: 600;
}

input.make-dropdown {
  transform: translateY(1px);
}
